<template>
  <section class="py-5" style="background:#fff">
    <v-container>
      <h2 class="text-center mb-3">كيف يعمل التطبيق</h2>
      <v-row class="align-center">
        <v-col cols="12" md="8">
          <v-col
            class="step mx-auto ml-2"
            cols="12"
            md="5"
            v-for="(step, i) in steps"
            :key="i"
          >
            <span class="ml-3 step-count">{{ i + 1 }}</span>
            <span style="font-size:14px">{{ step }}</span>
          </v-col>
        </v-col>
        <v-col cols="0" md="4" class="d-none d-md-inline">
          <v-img class="mx-auto" max-width="260" src="../assets/imgs/steps.svg">
          </v-img>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>
<script>
export default {
  name: "AppFeatures",
  data() {
    return {
      steps: [
        "نزل التطبيق من المتجر",
        "افتح التطبيق واكتب رقم تلفونك",
        "قم بملئ معلوماتك",
        "تصفح الاطباء واحجز موعدك",
      ],
    };
  },
};
</script>
<style scoped lang="scss">
.step {
  background: white;
  margin: 5px;
  float: right;
  border-radius: 5px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.2);
  .step-count {
    font-size: 1.8rem;
    color: indigo;
    font-weight: bold;
    font-family: Georgia;
  }
}
</style>
