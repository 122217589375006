/* eslint-disable */
<template>
  <section class="py-5" style="background: #f3f3f3">
    <h1 class="text-center mb-3">ماذا قالو عنا</h1>
    <v-container>
      <v-row>
        <v-col
          cols="12"
          sm="6"
          md="4"
          lg="3"
          v-for="(test, index) in testimonials"
          :key="index"
        >
          <v-card
            class="mx-auto text-center pa-5"
            max-width="280"
            elevation="1"
          >
            <v-avatar class="mb-4" size="40">
              <img :src="require(`@/assets/imgs/${test.img}`)" />
            </v-avatar>
            <p>{{ test.name }}</p>
            <v-divider></v-divider>
            <p>"{{ test.comment }}"</p>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>
<script>
export default {
  name: "TestimonialsComponent",
  data() {
    return {
      testimonials: [
        {
          name: "Mustafa Ayad",
          comment: "افضل تطبيق حجز اطباء في العراق",
          img: "unnamed.webp",
        },
        {
          name: "Mustafa Ayad",
          comment: "افضل تطبيق حجز اطباء في العراق",
          img: "unnamed.webp",
        },
        {
          name: "Mustafa Ayad",
          comment: "افضل تطبيق حجز اطباء في العراق",
          img: "unnamed.webp",
        },
        {
          name: "Mustafa Ayad",
          comment: "افضل تطبيق حجز اطباء في العراق",
          img: "unnamed.webp",
        },
      ],
    };
  },
};
</script>
<style scoped lang="scss"></style>
