import Vue from "vue";
import VueRouter from "vue-router";
import IndexPage from "../views/IndexPage.vue";
import PrivacyPolicyPage from "../views/PrivacyPolicyPage.vue";
import TermsConditionsPage from "../views/TermsConditionsPage.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "IndexPage",
    component: IndexPage,
  },
  {
    path: "/policy",
    name: "PrivacyPolicyPage",
    component: PrivacyPolicyPage,
  },
  {
    path: "/privacy",
    name: "PrivacyPage",
    component: PrivacyPolicyPage,
  },
  {
    path: "/terms",
    name: "TermsConditionsPage",
    component: TermsConditionsPage,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
