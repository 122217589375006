<template>
  <section class="py-5" style="background: #f6f6f6">
    <v-container>
      <v-row class="align-center">
        <v-col cols="12" sm="6" class="text-center">
          <v-img
            class="mx-auto mb-2"
            src="../assets/imgs/logo.png"
            width="150"
          ></v-img>
          <div class="mb-3 display-2" style="font-family: cairo !important">
            منصة طبيب تايم
          </div>
          <div class="mb-3 red--text text-h5">
            <span class="indigo--text">Do</span>ctor time platform
          </div>
          <p class="dark--text">تصفح جميع الاطباء بنقرة زر واحدة!</p>
          <v-btn class="ml-2">
            <a
              href="https://apps.apple.com/us/app/%D8%B7%D8%A8%D9%8A%D8%A8-%D8%AA%D8%A7%D9%8A%D9%85/id1638793849?uo=2"
              target="_blank"
            >
              تنزيل تطبيق
              <v-icon right color="grey"> mdi-apple </v-icon>
            </a>
          </v-btn>
          <v-btn>
            <a
              href="https://play.google.com/store/apps/details?id=com.asmartcastle.dr_time_v3"
              target="_blank"
            >
              تنزيل تطبيق
              <v-icon right color="green"> mdi-android </v-icon>
            </a>
          </v-btn>
        </v-col>

        <v-col sm="6" class="text-center d-none d-sm-block">
          <v-img
            class="mx-auto"
            contain
            src="../assets/imgs/doctor.png"
            max-height="450"
            max-width="450"
          ></v-img>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>
<script>
export default {
  name: "HeroComponent",
  data() {
    return {};
  },
};
</script>
<style scoped lang="scss">
a {
  text-decoration: none;
}
</style>
