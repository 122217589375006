<template>
  <v-app>
    <v-system-bar ref="main" height="25" dark color="indigo lighten-1">
      <span style="font-size: 12px"></span>
      <div class="ml-2">
        <v-icon right>mdi-email</v-icon>
        info@tses.io
      </div>
      <div>
        <v-icon right>mdi-phone</v-icon>
        07806123456
      </div>
    </v-system-bar>
    <!--  <appbar @navigate="navTo" /> -->
    <hero />
    <aboutUs />
    <ourPlans />
    <appFeatures ref="features" />
    <workSteps ref="steps" />
    <testimonials ref="testimonials" />
    <appFooter />
  </v-app>
</template>
<script>
//import appbar from "../components/Appbar.vue";
import appFooter from "../components/AppFooter.vue";
import aboutUs from "../components/AboutUs.vue";
import hero from "../components/Hero.vue";
import appFeatures from "../components/AppFeatures.vue";
import ourPlans from "../components/OurPlans.vue";
import workSteps from "../components/WorkSteps.vue";
import testimonials from "../components/Testimonials.vue";
export default {
  components: {
    appFooter,
    aboutUs,
    hero,
    appFeatures,
    testimonials,
    workSteps,
    ourPlans,
  },
  name: "IndexPage",
  data() {
    return {};
  },
  methods: {
    /*   navTo(ref) {
      console.log(ref);
      var element = this.$refs[ref];
      var top = element.offsetTop;
      console.log(element);
      window.scrollTo(0, top);
    }, */
  },
};
</script>
<style scoped lang="scss">
ul li {
  display: inline-block;
  margin-left: 15px;
  color: grey;
  font-size: 14px;
  cursor: pointer;
}
.active {
  color: black;
  font-weight: bold;
}
.platform-check-list {
  display: block;

  li {
    display: block;
    margin-bottom: 10px;
  }
}
.testimonials {
  background-image: url("../assets/imgs/kiss.jpg");
}
</style>
