<template>
  <section class="py-5" style="background:#fafafa">
    <v-container>
      <h1 class="text-center mb-3">خدمات طبيب تايم</h1>
      <v-row class="text-center mb-4">
        <v-col cols="12" md="4" v-for="(service, i) in appServices" :key="i">
          <v-card class="pa-10" color="grey lighten-5">
            <v-avatar
              rounded
              class="mb-4"
              circle
              size="50"
              color="indigo lighten-1"
            >
              <v-icon size="25" color="white">{{ service.icon }}</v-icon>
            </v-avatar>

            <h4 class="mb-3">{{ service.title }}</h4>
            <p>{{ service.body }}</p>
          </v-card>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <br />
      <v-row>
        <v-col cols="12">
          <h2 class="text-center">شنو يخليني انزل التطبيق؟</h2>
        </v-col>
        <v-col cols="12" md="6">
          <ul class="platform-check-list">
            <li v-for="(feature, i) in appFeatures" :key="i">
              <v-icon color="green" right>mdi-check-all</v-icon>
              <span>{{ feature }}</span>
            </li>
          </ul>
        </v-col>
        <v-col cols="0" md="6" class="d-none d-md-inline">
          <v-img
            class="mx-auto"
            max-width="250"
            src="../assets/imgs/hospital.png"
          >
          </v-img>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>
<script>
export default {
  name: "AppFeatures",
  data() {
    return {
      appServices: [
        {
          title: "اطباء بمختلف التخصصات",
          body: "مجموعة كبيرة من الاطباء المعتمدين في مختلف التخصصات الطبية",
          icon: "mdi-shape"
        },
        {
          title: "حجز المواعيد بضغطة زر",
          body:
            "وية طبيب تايم صار تكدر تحجز موعدك وية الطبيب الي تريدة بكل سهولة",
          icon: "mdi-bookmark"
        },
        {
          title: "سجل مرضي للمراجع",
          body:
            "سجل متكامل لجميع الزيارات المرضية يمكن الرجوع له في اي وقت لمتابعة حالة المراجع",
          icon: "mdi-history"
        }
      ],
      appFeatures: [
        "استعراض الاطباء بمختلف التخصصات والحجز من التطبيق",
        "امكانية الغاء الحجز قبل موعد الزيارة لاي سبب كان",
        "امكانية دفع فلوس الحجز من خلال البطاقة الالكترونية",
        "معرفة اوقات دوام العيادة",
        "معرفة حالة كل طبيب هل هوا متواجد ام لا",
        "وصفة طبية واضحة يمك بالتطبيق تبقة مورشفة يمك",
        "نصائح طبية مجانية من احسن الاطباء"
      ]
    };
  }
};
</script>
<style scoped lang="scss"></style>
