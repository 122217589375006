<template>
  <section class="py-5" style="background: #fff">
    <v-container>
      <h1 class="text-center mb-5">باقات طبيب تايم</h1>
      <v-row class="text-center mb-4 fill-height">
        <v-col cols="12" sm="6" md="3" v-for="(pkg, i) in packages" :key="i">
          <v-card class="rounded-xl" color="white ">
            <v-card-text style="min-height: 500px" class="px-2">
              <v-img
                class="rounded-xl mb-5"
                max-height="120"
                :src="require(`@/assets/imgs/${pkg.img}`)"
              >
              </v-img>
              <div class="price-style mb-5" dir="rtl">{{ pkg.price }}</div>
              <div class="mb-5 pkg-style">{{ pkg.package }}</div>
              <ul class="pr-10 mb-5 d-block">
                <li class="li-style" v-for="(li, x) in pkg.features" :key="x">
                  <v-icon>mdi-circle-small</v-icon> {{ li }}
                </li>
              </ul>
            </v-card-text>
            <v-spacer vertical></v-spacer>

            <v-card-actions>
              <v-btn
                @click="openRegForm()"
                class="reg-btn mb-4 mx-auto justify-bottom"
                >طلب الاشتراك</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>
<script>
export default {
  name: "AppFeatures",
  data() {
    return {
      packages: [
        {
          package: "الاشتراك المجاني",
          price: "0 دينار",
          features: [
            "مدة تجريبية لـ 3 اشهر",
            "استقبال واضافة حجوزات",
            "بروفايل وسجل مرضي للمراجع",
            "ارسال اشعارت للمراجعين",
            "حجز عن طريق التطبيق",
            "امكانية نشر العروض",
            "امكانية نشر النصائح طبية",
          ],
          icon: "mdi-shape",
          img: "free-plan.jpg",
        },
        {
          package: "الاشتراك الذهبي",
          price: "25,000 دينار",
          features: [
            "استقبال واضافة حجوزات",
            "بروفايل وسجل مرضي للمراجع",
            "ارسال اشعارت للمراجعين",
            "حجز عن طريق التطبيق",
          ],
          icon: "mdi-shape",
          img: "golden-plan.jpg",
        },
        {
          package: "الاشتراك البلاتيني",
          price: "35,000 دينار",
          features: [
            "استقبال واضافة حجوزات",
            "بروفايل وسجل مرضي للمراجع",
            "ارسال اشعارت للمراجعين",
            "حجز عن طريق التطبيق",
            "امكانية نشر العروض",
          ],
          icon: "mdi-shape",
          img: "platinum-plan.jpg",
        },
        {
          package: "الاشتراك الماسي",
          price: "50,000 دينار",
          features: [
            "استقبال واضافة حجوزات",
            "بروفايل وسجل مرضي للمراجع",
            "ارسال اشعارت للمراجعين",
            "حجز عن طريق التطبيق",
            "امكانية نشر العروض",
            "امكانية نشر النصائح طبية",
          ],
          icon: "mdi-shape",
          img: "diamond-plan.jpg",
        },
      ],
    };
  },
  methods: {
    openRegForm() {
      window.open("https://req.time.health", "_blank");
    },
  },
};
</script>
<style scoped lang="scss">
.price-style {
  font-weight: 600;
  font-size: 25px;
}
.pkg-style {
  font-weight: 400;
  font-size: 18px;
}
.li-style {
  font-size: 13px;
  text-align: right;
  margin-bottom: 10px;
}
.reg-btn {
  letter-spacing: 0;
  background-color: #4361ee !important;
  color: white;
}
</style>
