<template>
  <section ref="about" class="py-5" style="background:#fff">
    <v-container>
      <h1 class="text-center mb-3">شنو هو طبيب تايم ؟</h1>
      <p class="text-center" style="line-height:2">
        انطلقت خدمة طبيب تايم في العراق في آذار 2020 طبيب تايم هو خدمة مجانية
        تمكن المرضى من البحث عن الأطباء بجميع الاختصاصات وحجز موعد الكترونيا
        (اونلاين).من خلال تطبيق طبيب تايم تستطيع البحث عن الأطباء حسب الاختصاص
        والمحافظة او البحث بإسم الطبيب. طبيب تايم يحتوي على خدمات اخرى مثل
        العروض المخفضة من قبل الاطباء على العمليات الجراحية والأسنان وغيرها.
        كذلك يحتوي على خدمة العلاج خارج العراق والتي سوف تسهل على المرضى الوصول
        إلى ارقى مستشفيات العالم. طبيب تايم انطلق لدمج التكنولوجيا بالمجال الطبي
        وإضافة طريقة حجز جديدة و عصرية.
      </p>
    </v-container>
  </section>
</template>
<script>
export default {
  name: "AboutUs",
  data() {
    return {};
  }
};
</script>
<style scoped lang="scss"></style>
