<template>
  <v-footer dark padless>
    <v-card
      flat
      tile
      class="indigo darken-1 white--text text-center"
      width="100%"
    >
      <v-card-text>
        <v-btn
          @click="openUrl(icon.url)"
          v-for="icon in icons"
          :key="icon.url"
          class="mx-4 white--text"
          icon
        >
          <v-icon size="24px">
            {{ icon.iconName }}
          </v-icon>
        </v-btn>
      </v-card-text>

      <v-card-text
        class="white--text pt-0 d-block"
        style="display: block; width: 100%"
      >
        © {{ new Date().getFullYear() }} . All rights reserved by Technology
        Solutions for Electric Services.
      </v-card-text>

      <v-divider></v-divider>

      <v-card-text class="white--text text-center">
        {{ new Date().getFullYear() }} — <strong> DoctorTime</strong>
      </v-card-text>
    </v-card>
  </v-footer>
</template>
<script>
export default {
  name: "AppFooter",
  methods: {
    openUrl(url) {
      if (url != "") window.open(url, "_blank").focus();
    },
  },
  data() {
    return {
      icons: [
        {
          iconName: "mdi-facebook",
          url: "https://www.facebook.com/TabibTime/",
        },
        { iconName: "mdi-twitter", url: "" },
        { iconName: "mdi-linkedin", url: "" },
        { iconName: "mdi-instagram", url: "" },
      ],
    };
  },
};
</script>
<style scoped lang="scss"></style>
